import {
  District,
  SummaryStatistics,
  Survey,
  SurveyDeployment,
  SurveyDeploymentResponses,
  SurveyDeploymentStatistics,
  SurveyPagesByDemographic,
  SurveyDeploymentDistrictComparison,
} from "../../types";
import { createReducer, RootAction } from "typesafe-actions";
import actions from "../actions";
import {
  setSelectedSurveyDeployment,
  setSelectedDistrictComarprisonSurvey,
} from "./actions";

type ActionName =
  | "getSurveysDeployment"
  | "getAdminSurveysDeployment"
  | "addSurveyDeployment"
  | "updateSurveyDeployment"
  | "deleteSurveyDeployment"
  | "getSurveysDeploymentStatistics"
  | "getSummaryStatistics"
  | "getSurveyDeploymentsBySchool"
  | "getSurveyPagesGroupedByDemographic"
  | "downloadSampleLetters"
  | "closeSurveyDeployment"
  | "deleteSurveyDeploymentSoftly";

export type SurveysDeploymentState = {
  showSurveyDeploymentDialog?: boolean;

  surveyPages?: SurveyPagesByDemographic;

  responses?: SurveyDeploymentResponses;

  surveysDeployment: SurveyDeployment<District, Survey<number>>[];

  adminSurveysDeployment:SurveyDeployment<District, Survey<number>>[] |any;

  surveysDeploymentStatistics: SurveyDeploymentStatistics[];
  summaryStatistics: SummaryStatistics[];

  surveysDeploymentBySchool: SurveyDeployment<number, Survey<number>>[];

  selectedSurveyDeployment?: SurveyDeployment<District, Survey<number>>;

  selectedDistrictComarprisonSurvey: SurveyDeploymentDistrictComparison[];
  // surveyDeploymentOpenTimePeriod: SurveyDeploymentOpenTimePeriodBySchools[];

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: SurveysDeploymentState = {
  surveysDeployment: [],
  adminSurveysDeployment:[],
  surveysDeploymentStatistics: [],
  summaryStatistics: [],

  surveysDeploymentBySchool: [],

  selectedDistrictComarprisonSurvey: [],
  // surveyDeploymentOpenTimePeriod: [],

  loading: {},
  errors: {},
};

export const surveysDeploymentReducer = createReducer<
  SurveysDeploymentState,
  RootAction
>(initialState)
  .handleAction(setSelectedSurveyDeployment, (state, action) => ({
    ...state,
    selectedSurveyDeployment: action.payload,
  }))
  .handleAction(setSelectedDistrictComarprisonSurvey, (state, action) => ({
    ...state,
    selectedDistrictComarprisonSurvey: action.payload,
  }))
  .handleAction(
    actions.showSurveyDeploymentDialog,
    (state, action): SurveysDeploymentState => ({
      ...state,
      showSurveyDeploymentDialog: true,
    })
  )
  .handleAction(
    actions.hideSurveyDeploymentDialog,
    (state, action): SurveysDeploymentState => ({
      ...state,
      showSurveyDeploymentDialog: false,
      selectedSurveyDeployment: undefined,
    })
  )
  //get surveys deployment
  .handleAction(
    actions.getSurveysDeployment.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveysDeployment: true,
      },
      errors: {
        ...state.errors,
        getSurveysDeployment: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveysDeployment.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeployment: action.payload,
      loading: {
        ...state.loading,
        getSurveysDeployment: false,
      },
    })
  )
  .handleAction(
    actions.getSurveysDeployment.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveysDeployment: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveysDeployment: false,
      },
    })
  )

  // get Admin survey Deployment
  .handleAction(
    actions.getAdminSurveysDeployment.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        getAdminSurveysDeployment: true,
      },
      errors: {
        ...state.errors,
        getAdminSurveysDeployment: undefined,
      },
    })
  )
  .handleAction(
    actions.getAdminSurveysDeployment.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      adminSurveysDeployment: action.payload,
      loading: {
        ...state.loading,
        getAdminSurveysDeployment: false,
      },
    })
  )
  .handleAction(
    actions.getAdminSurveysDeployment.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        getAdminSurveysDeployment: action.payload,
      },
      loading: {
        ...state.loading,
        getAdminSurveysDeployment: false,
      },
    })
  )
  
  //get survey pages
  .handleAction(
    actions.getSurveyPagesGroupedByDemographic.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyPagesGroupedByDemographic: true,
      },
      errors: {
        ...state.errors,
        getSurveyPagesGroupedByDemographic: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyPagesGroupedByDemographic.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveyPages: action.payload,
      loading: {
        ...state.loading,
        getSurveyPagesGroupedByDemographic: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyPagesGroupedByDemographic.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyPagesGroupedByDemographic: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyPagesGroupedByDemographic: false,
      },
    })
  )
  //update surveys deployment
  .handleAction(
    actions.updateSurveyDeployment.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        updateSurveyDeployment: true,
      },
      errors: {
        ...state.errors,
        updateSurveyDeployment: undefined,
      },
    })
  )
  .handleAction(
    actions.updateSurveyDeployment.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeployment: state.surveysDeployment.map((item) =>
        item.id === action.payload.id ? action.payload : item
      ),
      loading: {
        ...state.loading,
        updateSurveyDeployment: false,
      },
    })
  )
  .handleAction(
    actions.updateSurveyDeployment.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        updateSurveyDeployment: action.payload,
      },
      loading: {
        ...state.loading,
        updateSurveyDeployment: false,
      },
    })
  )
  //add surveys deployment
  .handleAction(
    actions.addSurveyDeployment.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        addSurveyDeployment: true,
      },
      errors: {
        ...state.errors,
        addSurveyDeployment: undefined,
      },
    })
  )
  .handleAction(
    actions.addSurveyDeployment.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeployment: [action.payload, ...state.surveysDeployment],
      loading: {
        ...state.loading,
        addSurveyDeployment: false,
      },
    })
  )
  .handleAction(
    actions.addSurveyDeployment.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        addSurveyDeployment: action.payload,
      },
      loading: {
        ...state.loading,
        addSurveyDeployment: false,
      },
    })
  )
  //delete survey deployment
  .handleAction(
    actions.deleteSurveyDeployment.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteSurveyDeployment: true,
      },
      errors: {
        ...state.errors,
        deleteSurveyDeployment: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteSurveyDeployment.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeployment: state.surveysDeployment.filter(
        (item) => item.id !== action.payload
      ),
      loading: {
        ...state.loading,
        deleteSurveyDeployment: false,
      },
    })
  )
  .handleAction(
    actions.deleteSurveyDeployment.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteSurveyDeployment: action.payload,
      },
      loading: {
        ...state.loading,
        deleteSurveyDeployment: false,
      },
    })
  )
  //get surveys deployment statistics
  .handleAction(
    actions.getSurveysDeploymentStatistics.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveysDeploymentStatistics: true,
      },
      errors: {
        ...state.errors,
        getSurveysDeploymentStatistics: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveysDeploymentStatistics.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeploymentStatistics: action.payload,
      loading: {
        ...state.loading,
        getSurveysDeploymentStatistics: false,
      },
    })
  )
  .handleAction(
    actions.getSurveysDeploymentStatistics.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveysDeploymentStatistics: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveysDeploymentStatistics: false,
      },
    })
  )
  //get summary statistics
  .handleAction(
    actions.getSummaryStatistics.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        getSummaryStatistics: true,
      },
      errors: {
        ...state.errors,
        getSummaryStatistics: undefined,
      },
    })
  )
  .handleAction(
    actions.getSummaryStatistics.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      summaryStatistics: action.payload,
      loading: {
        ...state.loading,
        getSummaryStatistics: false,
      },
    })
  )
  .handleAction(
    actions.getSummaryStatistics.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        getSummaryStatistics: action.payload,
      },
      loading: {
        ...state.loading,
        getSummaryStatistics: false,
      },
    })
  )
  //get survey deployments by school
  .handleAction(
    actions.getSurveyDeploymentsBySchool.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyDeploymentsBySchool: true,
      },
      errors: {
        ...state.errors,
        getSurveyDeploymentsBySchool: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyDeploymentsBySchool.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeploymentBySchool: action.payload,
      loading: {
        ...state.loading,
        getSurveyDeploymentsBySchool: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyDeploymentsBySchool.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyDeploymentsBySchool: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyDeploymentsBySchool: false,
      },
    })
  )
  //download sample letters
  .handleAction(
    actions.downloadSampleLetters.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadSampleLetters: true,
      },
      errors: {
        ...state.errors,
        downloadSampleLetters: undefined,
      },
    })
  )
  .handleAction(
    actions.downloadSampleLetters.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadSampleLetters: false,
      },
    })
  )
  .handleAction(
    actions.downloadSampleLetters.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        downloadSampleLetters: action.payload,
      },
      loading: {
        ...state.loading,
        downloadSampleLetters: false,
      },
    })
  )
  //close survey deployment
  .handleAction(
    actions.closeSurveyDeployment.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        closeSurveyDeployment: true,
      },
      errors: {
        ...state.errors,
        closeSurveyDeployment: undefined,
      },
    })
  )
  .handleAction(
    actions.closeSurveyDeployment.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeployment: state.surveysDeployment.map((item) =>
        item.id === action.payload.id ? action.payload : item
      ),
      loading: {
        ...state.loading,
        closeSurveyDeployment: false,
      },
    })
  )
  .handleAction(
    actions.closeSurveyDeployment.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        closeSurveyDeployment: action.payload,
      },
      loading: {
        ...state.loading,
        closeSurveyDeployment: false,
      },
    })
  )
  //delete survey deployment softly
  .handleAction(
    actions.deleteSurveyDeploymentSoftly.request,
    (state, action): SurveysDeploymentState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteSurveyDeploymentSoftly: true,
      },
      errors: {
        ...state.errors,
        deleteSurveyDeploymentSoftly: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteSurveyDeploymentSoftly.success,
    (state, action): SurveysDeploymentState => ({
      ...state,
      surveysDeployment: state.surveysDeployment.filter(
        (item) => item.id !== action.payload
      ),
      loading: {
        ...state.loading,
        deleteSurveyDeploymentSoftly: false,
      },
    })
  )
  .handleAction(
    actions.deleteSurveyDeploymentSoftly.failure,
    (state, action): SurveysDeploymentState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteSurveyDeploymentSoftly: action.payload,
      },
      loading: {
        ...state.loading,
        deleteSurveyDeploymentSoftly: false,
      },
    })
  );
