import React, { useEffect, useMemo,useState } from "react";
import {
  District,
  InviteStatus,
  Plan,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
  SurveyDeploymentStateEnum,
  SurveyReportTabEnum,
  UserModel,
  UserNotesType,
} from "../../../../types";
import { useIntl } from "react-intl";
import { Button, Card, Divider,Spinner } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../../App";
import SurveyAdministrationTable from "./SurveyAdministrationTable";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import bookmarked from '../../../../static/images/ribben.png';
import { useDispatch, useSelector } from "react-redux";
import { getSurveyDeploymentsBySchool } from "../../../../store/surveys-deployment/actions";
import useBeforeBeginningDialog from "../../needs-and-goals/useBeforeBeginningDialog";
import artImg from "../../../../static/images/art.png"
import { isDistrictPlan } from "../../../../store/plans/selectors";
import leadrsImg from "../../../../static/images/leadersT.svg";
import { getTeamMembersByPlan } from "../../../../store/team-members/actions";
import { LeadersImg } from "../../../../static/icons/icon-components/LeadersImg";
import StrategicPlanWidget from "../strategic-plan-widget/StrategicPlanWidget";

type OwnProps = {
  activeSurveyDeployment: SurveyDeployment<District, Survey<number>>|any;
};

type Props = OwnProps;


type bookmarkStatuses = {
  user: UserModel;
  isDistrictPerson: boolean;
  bookmarksStarted: boolean;
};



type districtTeamBookmarkStatuses = {
  team: bookmarkStatuses[];
  district: bookmarkStatuses[];
};



const SurveyAdministrationWidgetBody: React.FC<Props> = (props: Props) => {
  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const surveyDeploymentsBySchool = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );
  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeployment
  );
  const surveyDeploymentsLoading = useSelector(
    (s) => s.surveysDeployment.loading.getSurveyDeploymentsBySchool
  );
  
  const selectedSchoolActiveSurveyDeployment = surveyDeployments?.find((sd)=>sd?.id === surveyDeploymentsBySchool[0]?.id) // on school of school drop down find schools recent survey deployment 

  const activeSurveyDeployment =isDistrictActivePlan? selectedSchoolActiveSurveyDeployment :props?.activeSurveyDeployment; 
  const intl = useIntl();
  
  const history = useHistory();
  const plans =useSelector((s)=>s.plans.plans);
  const districtSchoolTeamMembers = useSelector((s) => s.teamMembers.teamMembers);

  
  const activePlan = useSelector((s) => s.plans.activePlan);
  const planId = activePlan?.school.id
  const dispatch = useDispatch();
 
  const [displayedPlans, setDisplayedPlans] = useState<Plan[] | any>([]);
  const [selectedPlan,setSelectedPlan]=useState<any|undefined>(displayedPlans.length>0  && displayedPlans[0] );

  function sortPlans(a: Plan, b: Plan) {
    return a?.school?.name > b?.school?.name
      ? 1
      : b?.school?.name > a?.school?.name
      ? -1
      : 0;
  }

  useEffect(() => {
    let sortedPlans = plans?.filter(
      (p) => p.district.id === activePlan?.district?.id
    );
    sortedPlans = sortedPlans.sort(sortPlans);

    let sortedPlanDistrictIndex = sortedPlans.findIndex((sp) => !sp.school.id)

    let sortedPlanSchools = sortedPlans.filter((sp) => sp.school.id)
    setDisplayedPlans([
           ...sortedPlanSchools]);
  }, [plans, activePlan?.district]);

  const  {onOpen,beforeBeginningDialog:{statuses}}=useBeforeBeginningDialog({activeSurveyDeploymentID:activeSurveyDeployment?.id});


  const bookmarkStatuses = useMemo(() => {
    
    let schoolData:any=[];

    let bookmarkStatuses: districtTeamBookmarkStatuses = {
      team: [],
      district: [],
    };
    bookmarkStatuses.team = statuses.filter((m) => !m.isDistrictPerson);
    bookmarkStatuses.district = statuses.filter(
      (m) => m.bookmarksStarted && m.isDistrictPerson
    );

    //count bookmarks for school team 
    bookmarkStatuses?.team?.map((tMs) => {
      schoolData.push(tMs.bookmarksStarted)
      
   })
   bookmarkStatuses.team = schoolData.filter(Boolean);
    return bookmarkStatuses;
  }, [statuses]);

  const schoolMembers = useMemo(()=> {return districtSchoolTeamMembers.filter((dstm:any)=> dstm?.plan === selectedPlan?.id )},[districtSchoolTeamMembers,selectedPlan])


useEffect(() => {
    if (planId && !isDistrictActivePlan) {
      dispatch(getSurveyDeploymentsBySchool.request(planId));
    }
  }, [planId, dispatch,isDistrictActivePlan]);

  const handleViewDetailsClick = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanData, {
        workspace: "data",
        tabId: UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
      } as any)
    );
  };

  // const isSurveyHasNoResponses = useMemo(() => {
  //   return !Object.keys(activeSurveyDeployment?.responses ?? {}).length;
  // }, [activeSurveyDeployment]);


  const handleViewReportsClick = (
    surveyDeploymentId: number,
    schoolId?: number
  ) => {
    history.push(
      generatePath(AuthRoutes.YourPlanReports, {
        workspace: "report",
        tabId: SurveyReportTabEnum.ResponseRates,
        surveyDeploymentId: surveyDeploymentId,
        schoolId: schoolId,
      } as any)
    );
  };


useEffect(() => {
  if(displayedPlans.length > 0) {

    setSelectedPlan(displayedPlans[0])
  }
},[displayedPlans]);

  useEffect(()=>{
    if(selectedPlan && isDistrictActivePlan){ 
    dispatch(getTeamMembersByPlan.request({ planId: selectedPlan?.id,include_district_user:true }));
    dispatch(getSurveyDeploymentsBySchool.request(selectedPlan?.school?.id));

    }
  },[selectedPlan])
 
 
  const order = ["elementary_students", "students", "school_staff", "parents"];


  return (
  <>
 {isDistrictActivePlan && <div className="book-mark-process schoolInformation">
      <div className="text-xl font-bold text-blue-600 mb-4 text-center">
        <p className="mb-2">
          {intl.formatMessage({id:"app.titles.school-information"})}
          </p>
        <img src={artImg} alt="art Image" className="bookmarked-Img  m-auto" />
      </div>
      <div className="dropdownSchool">
        <FormControl className="filter" style={{width:"49%"}}>
          <InputLabel id="demo-select-small-label">Select School</InputLabel>
          
          <Select
          value={selectedPlan}
          onChange={(e:any)=>setSelectedPlan(e?.target?.value)}
          labelId="demo-select-small-label" id="demo-select-large" label="Select School">
            {displayedPlans?.map((dp:any,index:number)=>
            {return <MenuItem key={index} className="text-bold" value={dp}><p>{dp?.school?.name}</p></MenuItem>}
)}
            
          </Select>
        </FormControl>
      </div>
     
    </div>
    }


    
    <div className="book-mark-process">
      <div className="text-xl font-bold text-black mb-2">
        {isDistrictActivePlan ?"NJ SCI Survey Data Progress" :intl.formatMessage({id:"app.njsci.survey.bookmark.progress"})}
      </div>
      <div className="administraton-year">{activeSurveyDeployment?.deployment_name}</div>

      {surveyDeploymentsLoading ?<Spinner size={50} intent="primary" className="text-center" />:<>{activeSurveyDeployment?.responses ? 
      <>
      <h5 className="responce-rate">
        {activeSurveyDeployment?.state === SurveyDeploymentStateEnum.READY ? "Response Rates:" :intl.formatMessage({id:"app.survey-report.response-rates.data-report-not-generated"})}
      </h5></> :""}
      <Grid container rowSpacing={1} spacing={4} columns={12}>
        
        {surveyDeploymentsBySchool?.length ?[activeSurveyDeployment]?.map((asd:SurveyDeployment<District, Survey<number>>)=>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ width: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              
            {order.filter(key => (asd?.responses)?.hasOwnProperty(key))?.map((key) => asd?.responses && Object.keys(asd?.responses).find((name) => name === key)?Object.keys(asd?.responses).find((name) => name === key) :key)?.map((d:any)=>
            <Box gridColumn="span 6">
                <div className={`response-card ${d===SurveyDemographic.ElementaryStudents?"ltred-bg":d===SurveyDemographic.Students?"ltorange-bg":d===SurveyDemographic.SchoolStaff?"ltgreen-bg":"ltperple-bg"}`}>
                  <div className="text-2xl font-bold text-black py-2 px-4">
                  
                  {activeSurveyDeployment?.responses?activeSurveyDeployment.responses[d]?activeSurveyDeployment.responses[d]:0:""}
                  </div>
                  <p>{intl.formatMessage({id:`app.filters.respondent-type.distric-comparison.${d}`})}</p>
                </div>
              </Box>)}

              
            </Box>
          </Box>
          {asd?.state === SurveyDeploymentStateEnum.READY ?
          <a href="#" className="viewMore" onClick={()=>handleViewReportsClick(asd?.id,selectedPlan?.school?.id)}>
            {intl.formatMessage({id:"app.titles.view-reports"}).slice(0,-1)}
          </a>:
          <a href="#" className="viewMore" onClick={handleViewDetailsClick}>
          {intl.formatMessage({id:"app.titles.view-more-details"})}
        </a>
          }
        </Grid>)
        :
        <Grid item xs={12} sm={12} md={6}>
        {isDistrictActivePlan ? 
          <Card className="text-center bookmarked leadershipTeamCard rounded-lg pt-20 pb-20">
         
          <b>{intl.formatMessage({id: "app.dashboard.bookmark.progress.widget" })}</b>
          <p>Go to the Data Collection and Reports<br/> workspace to get started</p>
          
          
        
        </Card>
        :<Card className="text-center bookmarked-Card">
          
          <br />
          <h6>{intl.formatMessage({id: "app.dashboard.bookmark.progress.widget" })}</h6>
          <p>Go to the Data Collection and Reports<br/> workspace to get started</p>
          <br />
          <br />
        </Card>
        
      }
      </Grid>
      
      }
        
      
        {
        isDistrictActivePlan? <Grid item xs={12} sm={12} md={6}>
        <section className="-mt-10">
          <div className="text-xl font-bold text-black mb-4">
            {intl.formatMessage({id:"app.titles.teaming"})}
          </div>
          <Card className="leadershipTeamCard rounded-lg">
            <ul>
              <li><span className="card-circle">{schoolMembers?.length}</span>{intl.formatMessage({id:"app.titles.school-team-members"})}</li>
              <li><span className="card-circle">{schoolMembers?.filter((member)=> member.invited_status === InviteStatus.Expired || member.invited_status === InviteStatus.Sent)?.length}</span>{intl.formatMessage({id:"app.titles.team-table.sent-expired.status"})}</li>
              <li><span className="card-circle">{schoolMembers?.filter((member)=> member.user.is_active === false && member.invited_status === InviteStatus.Accepted)?.length} </span>Deactivated</li>
              <LeadersImg  className="leaders-icon" customFillColor="#2a035a"/>
              {/* <img src={leadrsImg} alt="leaders icon" className="leaders-icon" /> */}
            </ul>
          </Card>
        </section>
      </Grid>
      :
        activeSurveyDeployment?.state === SurveyDeploymentStateEnum.READY ? 
        <Grid item xs={12} sm={12} md={6}>
          <Card className="text-center bookmarked-Card">
          <img src={bookmarked} alt="Bookmarked Image" className="bookmarked-Img"/>
         <div>

            <span className="mr-5 font-bold text-xl">{bookmarkStatuses?.team.length}</span>
            <span>School Team Members have Bookmarked</span>
           
         </div>
            
           <div>
          <span className="mr-5 font-bold text-xl">{bookmarkStatuses?.district?.length}</span>
            <span>District Team Members have Bookmarked</span>
           </div>
          
          </Card>
        </Grid>
        :
        <Grid item xs={12} sm={12} md={6}>
          <Card className="text-center bookmarked-Card">
          <img src={bookmarked} alt="Bookmarked Image" className="bookmarked-Img"/>
            <h6>{intl.formatMessage({id:"app.titles.no-bookmark"})}</h6>
            <p>{intl.formatMessage({id:"app.titles.bookmark.generate-report"})}</p>
          </Card>
        </Grid>}

        
      </Grid>
      </>}
    </div>

    {isDistrictActivePlan && <StrategicPlanWidget activePlanId={selectedPlan?.id} isDistrictActivePlan={isDistrictActivePlan} />}
    </>
  );
};

export default SurveyAdministrationWidgetBody;