import React from "react";
import { useSelector,useDispatch } from "react-redux";

import Button from "@mui/material/Button";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { setSelectedSurvey } from "../../../store/surveys/actions";
import { hideSurveyDeploymentDialog } from "../../../store/surveys-deployment/actions";
import { useIntl } from "react-intl";
import { Callout } from "@blueprintjs/core";
import { useTable } from "react-table";


  
  type OwnProps = {
   data:any[],
   columns:any[]
  };
  
  type Props = OwnProps;

const  CreateNjsciSurveyModal: React.FC<Props>=(props)=> {
  const {columns,data} =props;

  const intl = useIntl();
  const dispatch = useDispatch();


    const show:any = useSelector(
        (s) => s.surveysDeployment.showSurveyDeploymentDialog
      );
    

      const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);
      const surveyDeployment = useSelector(
        (s) => s.surveysDeployment.selectedSurveyDeployment
      );



      const handleClose = () => {
        if (selectedSurvey) {
          dispatch(setSelectedSurvey(undefined));
        }
        dispatch(hideSurveyDeploymentDialog());
      };
    

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
      });




  return (
        
  
      <div className="survey-modal">
        <div className="flex justify-between">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {intl.formatMessage({
        id: "app.survey-deployment-dialog.create-edit-title",
      })}
        </DialogTitle>
        
          <CloseIcon fontSize="large" onClick={handleClose} style={{cursor:"pointer"}}/>
        </div>
        
        <DialogContent dividers className="content-padding">
           <h6 className="survey-modal-head">{selectedSurvey?.name}</h6>
           <p style={{fontSize:"medium"}}>Enter start and end dates for each group (can be edited later). End Dates cannot be set past June 30th of the current school year, after which survey links expire. Passwords are pre-assigned but can be edited (avoid long and complex passwords). Once saved, passwords are embedded in sample letters.</p>
           <div className="survey-table"> 
             <TableContainer component={Paper}>
                <Table {...getTableProps()} sx={{ minWidth: 550 }} aria-label="caption table">
                  <TableHead >
                  {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              < TableCell {...column.getHeaderProps()} sx={{ minWidth: 50 }} >{column.render('Header')==="Open Time Period" ?"Time Period":column.render('Header') }</TableCell>
            ))}
          </TableRow>
        ))}
                 
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                  {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
             {row.cells.map((cell) => (<>  
               {cell.column.Header ==="Passwords"?<TableCell align="left">{cell.render("Cell")}</TableCell>
  :<TableCell  {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>}
              </>))}
            </TableRow>
          );
        })}
                   
                  </TableBody>
                </Table>
              </TableContainer>
           </div>
           <div className="my-3 px-3">
              {/* <Callout intent="warning">{warningMessage}</Callout> */}
            </div>
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
        </div>
     
  );
}

export default CreateNjsciSurveyModal;
