import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Switch } from "@blueprintjs/core";
import DomainTitleRankingViewComparisonTable from "../table/DomainTitleRankingViewComparisonTable";
import { useSelector } from "react-redux";
import { DomainComparison, SurveyDemographic } from "../../../types";
import SurveyComparisonTableWrapper from "../table/SurveyComparisonTableWrapper";
import useComparison from "./useComparison";

type OwnProps = {
  tagId: string | undefined;
  domainId: number | undefined;
  surveyDeploymentId: number | undefined;
  availableDemographicsInSchool: SurveyDemographic[];
};

export const COMPARISON_COLORS = [
  "#003f5c",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];

type Props = OwnProps;

const SurveyComparisonTab: React.FC<Props> = (props) => {
  const { tagId, domainId, surveyDeploymentId,availableDemographicsInSchool } = props;

  const intl = useIntl();

  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);

  const [showTitleRankingView, setShowTitleRankingView] = useState<boolean>();

  const handleShowTitleRankingViewChange = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setShowTitleRankingView(checked);
  };

  const { showNonIdealState } = useComparison({
    surveyComparison: surveyComparison,
  });
  const demographics = availableDemographicsInSchool
  const surveysDeployment = useSelector(
    (s) => s.surveysDeployment.surveysDeployment
  );
  const surveysDeploymentBySchool = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );
  const sd = useMemo(() => {
    return (
      surveysDeployment.find((et) => et.id == surveyDeploymentId) ||
      surveysDeploymentBySchool.find((et) => et.id == surveyDeploymentId)
    );
  }, [surveyDeploymentId, surveysDeploymentBySchool]);

  const isVersion1 = useMemo(() => {
    return sd?.njsci_version === 1;
  }, [sd?.njsci_version]);

  return (
    <>
      <div className={"text-xl mb-2"}>
        {intl.formatMessage({ id: "app.surveys.reports.comparison.info" })}
      </div>

      <div>
        <div
          className={
            isVersion1 ? "flex justify-between mb-2" : "flex justify-end mb-2"
          }
        >
          {isVersion1 && (
            <span>
              <span className="blue-sq">&#9830;</span>
              <i>
                {" "}
                ={" "}
                {intl.formatMessage({
                  id: "app.collect-data.icon-info",
                })}
              </i>
            </span>
          )}

          {domainId === undefined && tagId === undefined && (
            <Switch
              checked={showTitleRankingView}
              onChange={handleShowTitleRankingViewChange}
              labelElement={
                <p className="font-bold whitespace-no-wrap text-xl mr-8">
                  {intl.formatMessage({
                    id: "app.survey-comparison.domain-title-ranking-view",
                  })}
                </p>
              }
              innerLabelChecked={intl.formatMessage({ id: "app.titles.on" })}
              innerLabel={intl.formatMessage({ id: "app.titles.off" })}
              alignIndicator="right"
            />
          )}
        </div>

        {showTitleRankingView ? (
          <div>
            <DomainTitleRankingViewComparisonTable
              demographics={demographics}
              surveyComparison={surveyComparison as DomainComparison[]}
              showNonIdealState={showNonIdealState}
            />
            <p className="mt-4">
              <i>
                {intl.formatMessage({
                  id: "app.titles.domain-title-ranking-view.description",
                })}
              </i>
            </p>
          </div>
        ) : (
          <SurveyComparisonTableWrapper
            tagId={tagId}
            domainId={domainId}
            surveyDeploymentId={surveyDeploymentId}
            demographics={demographics}
            surveyComparison={surveyComparison}
            showNonIdealState={showNonIdealState}
          />
        )}
      </div>
    </>
  );
};

export default SurveyComparisonTab;
