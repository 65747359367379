import React, { FunctionComponent, useMemo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { useIntl } from "react-intl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import useComparison from "../../../surveys/tab/useComparison";
import IconButton from "@mui/material/IconButton";
import { DomainComparison, DomainPriorityDecision, DomainPriorityTriageStatus, SurveyDemographic } from "../../../../types";
import CloseIcon from "@mui/icons-material/Close";
import {
  UpdateDomainPrioritiesDecisionRequest,
  UpdateDomainPrioritiesDecisionResponse,
} from "../../../../api/needs/types";
import { useBookmarksLevelInfo } from '../item-level-info-and-bookmarks-dialog/useBookmarksLevelInfo';
import { hidePrioritizeAndDoNotPrioritizeDomainDialog, showItemLevelInfoAndBookmarksDialog, updateDomainPrioritiesDecision, addDomainPriority } from "../../../../store/needs/actions";
import { dispatch } from "rxjs/internal/observable/pairs";
import { array, number } from "prop-types";
import {Callout, Spinner} from "@blueprintjs/core";
import Pusher from "pusher-js";
import {PUSHER_KEY} from "../../../../api/config";
import PriorityEvents from "../prioritize-and-do-not-prioritize-domain-dialog/PrioritizePusherEvents";
import useDomainPriority from "../../needs-and-goals/explore-strengths-and-needs-tab/useDomainPriority";

type OwnProps = {};

type Props = OwnProps;

const PrioritizeAndDoNotPrioritizeDomainDialogContent: FunctionComponent<Props> = props => {

  const intl = useIntl();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const [collapseMaxHeight, setCollapseMaxHeight] = useState<
  number | undefined
  >(undefined)

  const closePrioritizrModal = () => {

    dispatch(hidePrioritizeAndDoNotPrioritizeDomainDialog());
  }

  const { domain, demographic, meanScore, marksCount, prioritized } = useSelector(
    (s) => s.needs.prioritizeDialogs.PrioritizeAndDoNotPrioritizeDomainDialog
  );

  const surveyComparison: DomainComparison[] = useSelector((s: any) => s.surveyReports.surveyComparison);

  const { demographics } = useComparison({
    surveyComparison: surveyComparison,
  });
  
  const surveyDeploymentId = useSelector((s) => s.needs.surveyDeploymentId);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const domainId = domain?.id;

  const {respondents, domains} = useMemo(() => {

    if (!domainId) return { respondents: [], domains: [] };
    const filteredData = surveyComparison.filter(item => item.domain.id === domainId);
    return {
      respondents : filteredData.map(item => item.respondents),
      domains : filteredData.map(item => item.domain),
    }
  }, [surveyComparison, domain]);

  const respondent = Object.entries(respondents[0]);

  function formatScore(score : any) {
    if (score == null) return '';
    return score.toFixed(2)
    // return parseFloat(score.toFixed(2));
  }

  const length: number = 5;
  const questionsArray: number[] = Array.from({ length }, (_, index) => index);

  const openSummaryOfBookMark = ( domain : any, demographic:any, meanScore: number | undefined ) => {

    if (domain && demographic && meanScore && marksCount) {
      dispatch(
        showItemLevelInfoAndBookmarksDialog({
          domain: domain,
          demographic: demographic,
          meanScore: meanScore,
          marksCount: marksCount,
        })
      );
    }
  }

  const onComplete = (
    res?: UpdateDomainPrioritiesDecisionResponse,
    req?: UpdateDomainPrioritiesDecisionRequest
  ) => {
    setLoading(false);
    dispatch(hidePrioritizeAndDoNotPrioritizeDomainDialog());
  };

  const requestCall = useCallback(
    (
      decision: DomainPriorityDecision | null
    ) :UpdateDomainPrioritiesDecisionRequest | null => {
      if (surveyDeploymentId == null || activePlanId == null) {
        return null;
      }

      return {
        plan_id: activePlanId,
        survey_deployment: surveyDeploymentId,
        domain: domainId || 0,
        decision: decision,
        onComplete: onComplete,
      };
    },[domainId, activePlanId, surveyDeploymentId]
  );

  const domainPriorities = useSelector((s) => s.needs.domainPriorities);

  const domainPriorityFun = (demographic : SurveyDemographic|string) => {

    const relatedDomainPriority = domainPriorities.find(
      (dM) => dM.domain.id === domainId && dM.demographic === demographic
    );
    const isPrioritized = relatedDomainPriority?.triage_status ===
          DomainPriorityTriageStatus.PotentialPriority;
    return isPrioritized;
  }

  const requestPotentialPriority = (
    decision : DomainPriorityDecision,
    triageStatus : DomainPriorityTriageStatus,
  ) => {
    respondent.map((demographic, index) => {
     if(demographic[1]) {

     
      if (surveyDeploymentId == null || domainId == null || activePlanId == null || demographic[0] == null) {
        return null;
      }
      const prioritized = domainPriorityFun(demographic[0]);

      if( prioritized ) {
        const callResponse = requestCall(decision);
        handleRequestSent(callResponse);
      } else {
        setLoading(true);
        dispatch(
          addDomainPriority.request({
            plan: activePlanId,
            survey_deployment: surveyDeploymentId,
            domain: domainId,
            demographic: demographic[0],
            triage_status: triageStatus,
            decision: null,
            onComplete: () => {
              setLoading(false);
              const callResponse = requestCall(decision);
              handleRequestSent(callResponse);
            },
          })
        );
      }
     }else{
      return ""
     }
  })
  }

  const handleRequestSent = (
    request : any
  ) => {
    setLoading(true);
    dispatch(updateDomainPrioritiesDecision.request(request)); 
  };

  const handlePrioritize = () => {
    const requestBody = requestPotentialPriority(
      DomainPriorityDecision.Prioritized,
      DomainPriorityTriageStatus.PotentialPriority
    );
  };

  const handleDoNotPrioritize = () => {
    const requestBody = requestPotentialPriority(
      DomainPriorityDecision.Dismissed,
      DomainPriorityTriageStatus.PotentialPriority,
    );
  };

  const order = ["elementary_students", "students", "school_staff", "parents"];
  const respondentReorder =order.map((key) => respondent.find((name) => name[0] === key))




  return (
    <div className="sidearea">
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="domain-title"
      >
        {intl.formatMessage({
          id: `app.titles.priortize-domain`,
        })}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closePrioritizrModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 0,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers className="domain-card-content">
        <div className="class-room">
          <h5>{domain?.name}</h5>
          <table>
            <thead>
              <tr>
                <th>{intl.formatMessage({id:"app.titles.respondent.group"})}</th>
                <th>{intl.formatMessage({id:"app.titles.domain-mean"})}</th>
              </tr>
            </thead>
            <tbody>
            {respondentReorder.map((respondent, index) => (
               respondent && respondent[1] ? 
                <tr key={index}>
                  <td>{intl.formatMessage({
                      id: `app.filters.respondent-type.distric-comparison.${respondent[0]}`,
                    })}</td>
                  <td>{formatScore(respondent[1]?.mean_score)}</td>
                  <td>
                    <a href="javascript:void(0)" onClick={() => openSummaryOfBookMark(domains[0], respondent[0], respondent[1]?.mean_score)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="18"
                        viewBox="0 0 13 18"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.693604 1.18895V16.8591C0.693604 17.2331 1.14009 17.4213 1.40724 17.1592L5.33812 13.3141C5.9891 12.6782 7.04491 12.6793 7.69478 13.3141L11.6257 17.1592C11.8924 17.4207 12.3393 17.2338 12.3393 16.8591L12.3392 1.18895C12.3392 0.95741 12.151 0.768372 11.9193 0.768372H1.11361C0.881788 0.768372 0.693604 0.957406 0.693604 1.18895Z"
                          fill="#195A94"
                        />
                      </svg>
                      {intl.formatMessage({
                        id: `app.title.item-level-information-and-bookmarks`,
                      })}
                    </a>
                  </td>
                </tr> : ''
              ) )}
            </tbody>
          </table>
        </div>
        <div className="question-list">
        <h6>{intl.formatMessage({
            id: `app.titles.focusing-questions`,
          })}</h6>
                          {domain?.focusing_questions ? (
          <>
            <div
              style={
                collapseMaxHeight
                  ? { maxHeight: collapseMaxHeight, overflow: "hidden" }
                  : undefined
              }
            >
              <div
                className={"jodit-dangerous-content"}
                dangerouslySetInnerHTML={{
                  __html: domain?.focusing_questions,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <Callout
              intent="warning"
              title={intl.formatMessage({
                id: "app.needs.no-focusing-questions",
              })}
            />
          </>
        )}
        </div>
        {loading ? <Spinner intent={"primary"} /> : ''}
        <div className="footer-botton">
            <button onClick={handleDoNotPrioritize} className="btn btn-secoundary">{intl.formatMessage({
              id: `app.titles.do-not-prioritize`,
            })}</button>
            <button onClick={handlePrioritize} className="btn btn-primary">{intl.formatMessage({
              id: `app.titles.priortize`,
            })}</button>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </div>
  );
};

export default PrioritizeAndDoNotPrioritizeDomainDialogContent;