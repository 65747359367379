import React, { FunctionComponent, useEffect } from "react";
import { Table, TableContainer,TableHead,TableCell,TableRow,TableBody,Checkbox, Paper } from "@mui/material";
import { usePagination, UseRowSelectInstanceProps, useTable } from "react-table";
import TableCheckboxCell from "../../../common/TableCheckboxCell";
import { useRowSelect } from "react-table";
import TablePagination from "../../../common/TablePagination";
import { useSelector } from "react-redux";


type DisabledState = {
    checked?: boolean;
    disabled?: boolean;
  };

type OwnProps = {
    data: any[];
    columns: any[];
    checkboxSelection?: boolean;
    onSelectedChange?: (any: []) => void;
    isCheckBoxDisable?: (entity: any) => DisabledState | undefined;
    customPagination?: boolean;
    customSetPageSize?: (page: number) => void;
  customPageCount: number;
  customGoToPage?: (page: number) => void;
  customPageIndex?: number;
  customPageSize?: number;
   
  };
  
  type Props = OwnProps;
  

const NewSurveyDeploymentTable: FunctionComponent<Props> = (props) =>  {

  const CUSTOMPAGECOUNT = 1;
  const CUSTOMPAGESIZE = 10;
  const COSTOMPAGEINDEX = 0
  
    const {
        data,
        columns,
        children,
        checkboxSelection,
        onSelectedChange,
        isCheckBoxDisable,
        customPagination,
        customSetPageSize,
        customGoToPage,
        customPageCount = CUSTOMPAGECOUNT,
        customPageIndex = COSTOMPAGEINDEX,
        customPageSize = CUSTOMPAGESIZE,
       
      } = props;



      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    
        page,
        pageCount,
        gotoPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize },
      } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: 10 },
        },
        // useSortBy,
        usePagination,
        useRowSelect,
        // useSticky,
        (hooks) => {
          if (checkboxSelection) {
            hooks.visibleColumns.push((columns) => [
              {
                id: "selection",
                width: "0%",
                Header: ({
                  getToggleAllRowsSelectedProps,
                }: UseRowSelectInstanceProps<any>) => (
                  <TableCheckboxCell
                    {...getToggleAllRowsSelectedProps()}
                    // disabled={readOnly}
                  />
                ),
                Cell: ({ row }: any) => (
                  <TableCheckboxCell
                    key={row.original.id}
                    {...row.getToggleRowSelectedProps()}
                    {...(isCheckBoxDisable &&
                      isCheckBoxDisable(row.original?.state))}
                  />
                ),
              },
              ...columns,
            ]);
          }
        }
      );


      useEffect(() => {
        if (onSelectedChange) {
          onSelectedChange(selectedFlatRows.map((r) => r.original) as any);
        }
      }, [selectedFlatRows.length]);

  
    return (
      <>
      <div
        className="data-collection-table"
        style={{ maxHeight: "30rem", overflowY: "scroll" }}
      >
        <TableContainer component={Paper}>
          <Table {...getTableProps()} sx={{ minWidth: 800 }}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.id !== "selection" ? column.render("Header") : ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
          
                       <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>

          </Table>
          {/* <div style={{ height: "400px", overflowY: "auto" }}>
            <Table {...getTableProps()}>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div> */}
        </TableContainer>
      </div>
      {data.length > 0 && (
        <TablePagination
        gotoPage={gotoPage}
        {...(customPagination
          ? { pageCount: customPageCount, pageIndex: customPageIndex }
          : { pageCount: pageCount, pageIndex: pageIndex })}
        customPagination={customPagination}
        customGoToPage={customGoToPage}
      />
      )}
        </>
    );
}

export default NewSurveyDeploymentTable;
